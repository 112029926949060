<!-- Created by henian.xu on 2018/7/20. -->

<template>
    <page
        back
    >
        <el-row>
            <el-form
                ref="addForm"
                size="small"
                :model="formModel"
                :rules="formRules"
            >
                <el-col :span="24">
                    <el-form-item
                        prop="buyerBaseFreight"
                        label="买家基础运费"
                        label-width="9em"
                    >
                        <el-input-number
                            controls-position="right"
                            :min="0"
                            :precision="2"
                            v-model.number="formModel.buyerBaseFreight"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="distributionBaseFreight"
                        label="配送员基础运费"
                        label-width="9em"
                    >
                        <el-input-number
                            controls-position="right"
                            :min="0"
                            :precision="2"
                            v-model.number="formModel.distributionBaseFreight"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="distributionType"
                        label="配送方式"
                        label-width="9em"
                    >
                        <dictionaries-picker
                            type="storeDistributionType"
                            v-model="formModel.distributionType"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="distributionDistance"
                        label="配送距离（米）"
                        label-width="9em"
                        v-if="formModel.distributionType == 1"
                    >
                        <el-input-number
                            controls-position="right"
                            :min="1"
                            :precision="0"
                            v-model.number="formModel.distributionDistance"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="electronicFenceConfig"
                        label="配送区域"
                        label-width="9em"
                        v-if="formModel.distributionType == 2"
                    >
                        <MonitorMap
                            ref="monitorMap"
                            show-distance-tool
                            show-drawing-manager
                            :drawing-manager-enable-editing="true"
                            show-map-type
                            @ready="onMapReady"
                        ></MonitorMap>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <!--                    <el-form-item
                                            prop="prepareGoodsDesc"
                                            label="备货描述"
                                            label-width="9em"
                                        >
                                            <el-input
                                                v-model="formModel.prepareGoodsDesc"
                                                auto-complete="off"
                                            />
                                        </el-form-item>-->
                    <el-form-item
                        prop="prepareGoodsMode"
                        label="服务时间类型"
                        label-width="9em"
                    >
                        <el-radio-group v-model="formModel.prepareGoodsMode">
                            <el-radio :label="0">
                                每天
                            </el-radio>
                            <el-radio :label="1">
                                周期
                            </el-radio>
                        </el-radio-group>
                    </el-form-item>
                </el-col>
                <el-col :span="24">
                    <el-form-item
                        label="服务时间段规则"
                        label-width="9em"
                    >
                        <div
                            class="ma-b"
                        >
                            <el-button-group>
                                <el-button
                                    type="success"
                                    icon="el-icon-plus"
                                    size="small"
                                    @click="onAddItem"
                                >
                                    新增
                                </el-button>
                            </el-button-group>
                        </div>
                        <!-- 表格 -->
                        <el-table
                            stripe
                            border
                            size="small"
                            :data="serviceTimeList"
                            style="width: 100%"
                        >
                            <el-table-column
                                prop="serviceFrequency"
                                label="下单结束日期"
                                width="120"
                                v-if="formModel.prepareGoodsMode == 1"
                            >
                                <template slot-scope="scope">
                                    <el-select
                                        v-model="scope.row.serviceFrequency"
                                        placeholder="请选择"
                                        class="w-100px"
                                        filterable
                                    >
                                        <el-option
                                            v-for="item in serviceFrequencyList"
                                            :key="item.value"
                                            :label="item.label"
                                            :value="item.value"
                                        />
                                    </el-select>
                                </template>
                            </el-table-column>
                            <el-table-column
                                prop="orderEndTime"
                                label="下单结束时间"
                                width="165"
                            >
                                <template slot-scope="scope">
                                    <el-select
                                        v-model="scope.row.orderEndTime1"
                                        placeholder="小时"
                                        class="w-65px"
                                        filterable
                                    >
                                        <el-option
                                            v-for="item in hourSelect"
                                            :key="item"
                                            :label="item"
                                            :value="item"
                                        />
                                    </el-select>
                                    :
                                    <el-select
                                        v-model="scope.row.orderEndTime2"
                                        placeholder="分钟"
                                        class="w-65px"
                                        filterable
                                    >
                                        <el-option
                                            v-for="item in minSelect"
                                            :key="item"
                                            :label="item"
                                            :value="item"
                                        />
                                    </el-select>
                                </template>
                            </el-table-column>
                            <el-table-column
                                prop="intervalDays"
                                label="服务时间间隔"
                                min-width="100"
                            >
                                <template slot-scope="scope">
                                    <el-input-number
                                        controls-position="right"
                                        :min="0"
                                        :max="1000"
                                        v-model.number="scope.row.intervalDays"
                                        class="w-80px"
                                        size="mini"
                                    />
                                </template>
                            </el-table-column>
                            <el-table-column
                                prop="beginTime"
                                label="服务开始时间"
                                width="165"
                            >
                                <template slot-scope="scope">
                                    <el-select
                                        v-model="scope.row.beginTime1"
                                        placeholder="小时"
                                        class="w-65px"
                                        filterable
                                    >
                                        <el-option
                                            v-for="item in hourSelect"
                                            :key="item"
                                            :label="item"
                                            :value="item"
                                        />
                                    </el-select>
                                    :
                                    <el-select
                                        v-model="scope.row.beginTime2"
                                        placeholder="分钟"
                                        class="w-65px"
                                        filterable
                                    >
                                        <el-option
                                            v-for="item in minSelect"
                                            :key="item"
                                            :label="item"
                                            :value="item"
                                        />
                                    </el-select>
                                </template>
                            </el-table-column>
                            <el-table-column
                                prop="endTime"
                                label="服务结束时间"
                                width="165"
                            >
                                <template slot-scope="scope">
                                    <el-select
                                        v-model="scope.row.endTime1"
                                        placeholder="小时"
                                        class="w-65px"
                                        filterable
                                    >
                                        <el-option
                                            v-for="item in hourSelect"
                                            :key="item"
                                            :label="item"
                                            :value="item"
                                        />
                                    </el-select>
                                    :
                                    <el-select
                                        v-model="scope.row.endTime2"
                                        placeholder="分钟"
                                        class="w-65px"
                                        filterable
                                    >
                                        <el-option
                                            v-for="item in minSelect"
                                            :key="item"
                                            :label="item"
                                            :value="item"
                                        />
                                    </el-select>
                                </template>
                            </el-table-column>
                            <el-table-column
                                prop="serviceLimit"
                                label="服务人数上限(0为不限制)"
                                min-width="160"
                            >
                                <template slot-scope="scope">
                                    <el-input-number
                                        controls-position="right"
                                        :min="0"
                                        :max="1000"
                                        v-model.number="scope.row.serviceLimit"
                                        size="mini"
                                    />
                                </template>
                            </el-table-column>
                            <el-table-column
                                prop="freight"
                                label="买家增量运费"
                                min-width="110"
                            >
                                <template slot-scope="scope">
                                    <el-input-number
                                        controls-position="right"
                                        :min="0"
                                        :precision="2"
                                        v-model.number="scope.row.freight"
                                        class="w-95px"
                                        size="mini"
                                    />
                                </template>
                            </el-table-column>
                            <el-table-column
                                prop="distributionFreight"
                                label="配送员增量运费"
                                min-width="110"
                            >
                                <template slot-scope="scope">
                                    <el-input-number
                                        controls-position="right"
                                        :min="0"
                                        :precision="2"
                                        v-model.number="scope.row.distributionFreight"
                                        class="w-95px"
                                        size="mini"
                                    />
                                </template>
                            </el-table-column>
                            <el-table-column
                                prop="isAvailable"
                                label="是否可用"
                                min-width="70"
                            >
                                <template slot-scope="scope">
                                    <el-switch
                                        v-model="scope.row.isAvailable"
                                    />
                                </template>
                            </el-table-column>
                            <el-table-column
                                fixed="right"
                                label="操作"
                                width="45"
                            >
                                <template slot-scope="scope">
                                    <el-button
                                        type="text"
                                        size="small"
                                        @click="onDeleteItem(scope.row)"
                                    >
                                        删除
                                    </el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-form-item>
                    <el-form-item
                        label="门店数量规则"
                        label-width="9em"
                    >
                        <el-button
                            type="text"
                            size="small"
                            @click="onOrderNumRule()"
                        >
                            新增规则
                        </el-button>
                        <el-table
                            stripe
                            border
                            size="small"
                            :data="storeNumRuleList"
                            style="width: 100%"
                        >
                            <el-table-column
                                prop="rangeStart"
                                label="开始值"
                                min-width="100"
                            >
                                <template slot-scope="scope2">
                                    <el-input
                                        v-model="scope2.row.rangeStart"
                                    />
                                </template>
                            </el-table-column>
                            <el-table-column
                                prop="rangeEnd"
                                label="结束值"
                                min-width="100"
                            >
                                <template slot-scope="scope2">
                                    <el-input
                                        v-model="scope2.row.rangeEnd"
                                    />
                                </template>
                            </el-table-column>
                            <el-table-column
                                prop="increaseValue"
                                label="增量值"
                                min-width="100"
                            >
                                <template slot-scope="scope2">
                                    <el-input
                                        v-model="scope2.row.increaseValue"
                                    />
                                </template>
                            </el-table-column>
                            <el-table-column
                                prop="freight"
                                label="买家增量运费"
                                min-width="160"
                            >
                                <template slot-scope="scope2">
                                    <el-input-number
                                        controls-position="right"
                                        :min="0"
                                        :precision="2"
                                        v-model.number="scope2.row.freight"
                                    />
                                </template>
                            </el-table-column>
                            <el-table-column
                                prop="distributionFreight"
                                label="配送员增量运费"
                                min-width="160"
                            >
                                <template slot-scope="scope2">
                                    <el-input-number
                                        controls-position="right"
                                        :min="0"
                                        :precision="2"
                                        v-model.number="scope2.row.distributionFreight"
                                    />
                                </template>
                            </el-table-column>
                            <el-table-column
                                fixed="right"
                                label="操作"
                                width="90"
                            >
                                <template slot-scope="ruleScope">
                                    <el-button
                                        type="text"
                                        size="small"
                                        @click="onDelOrderNumRule(ruleScope.row)"
                                    >
                                        删除规则
                                    </el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-form-item>
                    <el-form-item
                        prop="minPackingAmount"
                        label="最小包装费"
                        label-width="9em"
                    >
                        <el-input-number
                            controls-position="right"
                            :min="0"
                            :precision="2"
                            v-model.number="formModel.minPackingAmount"
                        />（为0不限制）
                    </el-form-item>
                    <el-form-item
                        prop="maxPackingAmount"
                        label="最大包装费"
                        label-width="9em"
                    >
                        <el-input-number
                            controls-position="right"
                            :min="0"
                            :precision="2"
                            v-model.number="formModel.maxPackingAmount"
                        />（为0不限制）
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item
                        label-width="9em"
                    >
                        <el-button
                            type="primary"
                            size="small"
                            @click="onConfirm"
                        >
                            保 存
                        </el-button>
                        <el-button
                            size="small"
                            @click="$router.back()"
                        >
                            返 回
                        </el-button>
                    </el-form-item>
                </el-col>
            </el-form>
        </el-row>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';

export default {
    name: 'ShopStoreServiceTime',
    mixins: [pagesMixin],
    data() {
        return {
            // 表单数据
            formModel: {
                businessDistrictId: '',
                prepareGoodsDesc: '',
                prepareGoodsMode: 0,

                distributionType: '',
                distributionDistance: '',
                electronicFenceType: '',
                electronicFenceConfig: '',
                buyerBaseFreight: '',
                distributionBaseFreight: '',
                storeNumRuleList: '',
                minPackingAmount: 0,
                maxPackingAmount: 0,
            },
            // 表单校验规则
            formRules: {
                buyerBaseFreight: {
                    required: true,
                    message: '请输入',
                    trigger: 'blur',
                },
                distributionBaseFreight: {
                    required: true,
                    message: '请输入',
                    trigger: 'blur',
                },
                prepareGoodsDesc: {
                    required: true,
                    message: '请输入备货描述',
                    trigger: 'blur',
                },
                prepareGoodsMode: {
                    required: true,
                    message: '请输入选择备货方式',
                },
                distributionType: {
                    required: true,
                    message: '请选择门店配送方式',
                },
                maxPackingAmount: {
                    required: true,
                    message: '请输入',
                    trigger: 'blur',
                },
                minPackingAmount: {
                    required: true,
                    message: '请输入',
                    trigger: 'blur',
                },
            },
            // 表格数据
            serviceTimeList: [],
            serviceFrequencyList: [
                { value: 1, label: '星期一' },
                { value: 2, label: '星期二' },
                { value: 3, label: '星期三' },
                { value: 4, label: '星期四' },
                { value: 5, label: '星期五' },
                { value: 6, label: '星期六' },
                { value: 7, label: '星期日' },
            ],
            hourSelect: [
                '00',
                '01',
                '02',
                '03',
                '04',
                '05',
                '06',
                '07',
                '08',
                '09',
                '10',
                '11',
                '12',
                '13',
                '14',
                '15',
                '16',
                '17',
                '18',
                '19',
                '20',
                '21',
                '22',
                '23',
            ],
            minSelect: ['00', '30'],
            storeNumRuleList: [],
        };
    },
    methods: {
        onConfirm() {
            const { addForm } = this.$refs;
            addForm.validate().then(() => {
                this.serviceTimeList.forEach(item => {
                    item.beginTime = `${item.beginTime1}:${item.beginTime2}`;
                    item.endTime = `${item.endTime1}:${item.endTime2}`;
                    item.orderEndTime = `${item.orderEndTime1}:${item.orderEndTime2}`;

                    if (this.formModel.prepareGoodsMode === 0) {
                        item.serviceFrequency = 0;
                    }
                });
                if (+this.formModel.distributionType === 2) {
                    const { $refs } = this;
                    const { monitorMap } = $refs;
                    if (!monitorMap) return;
                    const config = monitorMap.getDrawData();
                    if (!config || config.length === 0) {
                        this.$message({
                            message: '配置不能为空',
                            type: 'error',
                        });
                        return;
                    }
                    if (config.length !== 1) {
                        this.$message({
                            message: '配置只能为一个',
                            type: 'error',
                        });
                        return;
                    }
                    if (config[0].type === 'circle') {
                        this.formModel.electronicFenceType = 1;
                    } else if (config[0].type === 'polygon') {
                        this.formModel.electronicFenceType = 2;
                    } else if (config[0].type === 'driving') {
                        this.formModel.electronicFenceType = 3;
                    }
                    this.formModel.electronicFenceConfig = JSON.stringify(config);
                }
                const otherConfig = {
                    electronicFenceType: this.formModel.electronicFenceType,
                    electronicFenceConfig: this.formModel.electronicFenceConfig,
                    buyerBaseFreight: this.formModel.buyerBaseFreight,
                    distributionBaseFreight: this.formModel.distributionBaseFreight,
                    storeNumRuleList: JSON.stringify(this.storeNumRuleList),
                    minPackingAmount: this.formModel.minPackingAmount,
                    maxPackingAmount: this.formModel.maxPackingAmount,
                };
                this.$api.Sp.BusinessDistrictServiceTime.save({
                    ...this.formModel,
                    serviceTimeJson: JSON.stringify(this.serviceTimeList),
                    otherConfigJson: JSON.stringify(otherConfig),
                }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.$router.back();
                });
            });
        },
        init() {
            this.$api.Sp.BusinessDistrictServiceTime.getDetail({
                businessDistrictId: this.formModel.businessDistrictId,
            }).then(json => {
                const res = json.data.data;
                this.$utils.formModelMerge(this.formModel, res);
                this.serviceTimeList = json.data.data.serviceTimeList || [];
                this.serviceTimeList.forEach(item => {
                    let beginTime1 = null;
                    let beginTime2 = null;
                    let endTime1 = null;
                    let endTime2 = null;
                    [beginTime1, beginTime2] = item.beginTime.split(':');
                    [endTime1, endTime2] = item.endTime.split(':');
                    this.$set(item, 'beginTime1', beginTime1);
                    this.$set(item, 'beginTime2', beginTime2);
                    this.$set(item, 'endTime1', endTime1);
                    this.$set(item, 'endTime2', endTime2);

                    if (item.orderEndTime) {
                        let orderEndTime1 = null;
                        let orderEndTime2 = null;
                        [orderEndTime1, orderEndTime2] = item.orderEndTime.split(':');
                        this.$set(item, 'orderEndTime1', orderEndTime1);
                        this.$set(item, 'orderEndTime2', orderEndTime2);
                    }

                    if (item.serviceFrequency === 0) {
                        item.serviceFrequency = undefined;
                    }
                });
                this.storeNumRuleList = json.data.data.storeNumRuleList || [];
            });
        },
        /* -- 事件 -- */
        onAddItem() {
            this.serviceTimeList.push({
                intervalDays: undefined,
                serviceLimit: 0,
                freight: 0,
                distributionFreight: 0,
                isAvailable: true,
            });
        },
        onDeleteItem(row) {
            this.serviceTimeList.splice(this.serviceTimeList.indexOf(row), 1);
        },
        onMapReady(/* { BMap, map } */) {
            this.initMap();
        },
        initMap() {
            const { formModel } = this;
            if (`${formModel.distributionType}` !== '2' || !formModel.electronicFenceConfig) return;
            this.$nextTick(() => {
                this.$refs.monitorMap.setDrawData(JSON.parse(formModel.electronicFenceConfig));
            });
        },
        onOrderNumRule() {
            this.storeNumRuleList.push({
                freight: 0,
                distributionFreight: 0,
            });
        },
        onDelOrderNumRule(row) {
            this.storeNumRuleList.splice(this.storeNumRuleList.indexOf(row), 1);
        },
    },
    created() {
        this.formModel.businessDistrictId = this.$route.params.id;
        this.init();
    },
};
</script>

<style lang="scss">
</style>
