<!-- Created by henian.xu on 2018/7/20. -->

<template>
    <page
        back
    >
        <el-row>
            <el-col :span="24">
                <el-form
                    ref="addForm"
                    size="small"
                    :model="formModel"
                    :rules="formRules"
                >
                    <el-col :span="24">
                        <el-form-item
                            prop="businessDistrictName"
                            label="商业区名称"
                            label-width="10em"
                        >
                            <el-input
                                v-model="formModel.businessDistrictName"
                                auto-complete="off"
                            />
                        </el-form-item>
                        <el-form-item
                            prop="businessDistrictSn"
                            label="商业区编号"
                            label-width="10em"
                        >
                            <el-input
                                v-model="formModel.businessDistrictSn"
                                auto-complete="off"
                            />
                        </el-form-item>
                        <el-form-item
                            prop="businessDistrictLogo"
                            label="商业区Logo"
                            label-width="10em"
                        >
                            <upload
                                validate-md5
                                action="/rs/attachment/uploadFileLibrary"
                                :limit="1"
                                v-model="formModel.businessDistrictLogo"
                            />
                        </el-form-item>
                        <el-form-item
                            prop="openTime"
                            label="营业时间"
                            label-width="10em"
                        >
                            <el-input
                                v-model="formModel.openTime"
                                auto-complete="off"
                            />
                        </el-form-item>
                        <el-form-item
                            prop="businessDistrictDesc"
                            label="商业区简介"
                            label-width="10em"
                        >
                            <el-input
                                type="textarea"
                                v-model="formModel.businessDistrictDesc"
                                auto-complete="off"
                            />
                        </el-form-item>
                        <el-form-item
                            prop="regionId"
                            label="商业区地址"
                            label-width="10em"
                        >
                            <CascaderPicker
                                filterable
                                :api-class="selectApi"
                                v-model="formModel.regionId"
                                :p-id="0"
                                :min-lv="1"
                                :query-params="{minLv:1}"
                            />
                        </el-form-item>
                        <el-form-item
                            prop="detailAddress"
                            label="商业区详细地址"
                            label-width="10em"
                        >
                            <el-input
                                v-model="formModel.detailAddress"
                                auto-complete="off"
                            />
                        </el-form-item>
                        <el-form-item
                            prop="latLng"
                            label="商业区坐标"
                            label-width="10em"
                        >
                            <LatLngPicker v-model="formModel.latLng"/>
                        </el-form-item>
                        <el-form-item
                            prop="contactFullname"
                            label="联系人姓名"
                            label-width="10em"
                        >
                            <el-input
                                v-model="formModel.contactFullname"
                                auto-complete="off"
                            />
                        </el-form-item>
                        <el-form-item
                            prop="contactTel"
                            label="联系人电话"
                            label-width="10em"
                        >
                            <el-input
                                v-model="formModel.contactTel"
                                auto-complete="off"
                            />
                        </el-form-item>
                        <el-form-item
                            prop="isDefault"
                            label="是否默认"
                            label-width="10em"
                        >
                            <el-switch
                                v-model="formModel.isDefault"
                            />
                        </el-form-item>
                        <!--                        <el-form-item
                                                    prop="isAvailable"
                                                    label="是否可用"
                                                    label-width="10em"
                                                >
                                                    <el-switch
                                                        v-model="formModel.isAvailable"
                                                    />
                                                </el-form-item>-->
                        <el-form-item
                            prop="businessDistrictStatus"
                            label="商业区状态"
                            label-width="10em"
                        >
                            <dictionaries-picker
                                type="businessDistrictStatus"
                                v-model="formModel.businessDistrictStatus"
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item
                            label-width="10em"
                        >
                            <el-button
                                type="primary"
                                size="small"
                                @click="onConfirm"
                            >
                                保 存
                            </el-button>
                            <el-button
                                size="small"
                                @click="$router.back()"
                            >
                                返 回
                            </el-button>
                        </el-form-item>
                    </el-col>
                </el-form>
            </el-col>
        </el-row>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';

export default {
    name: 'BusinessDistrictEdit',
    mixins: [pagesMixin],
    data() {
        return {
            businessDistrictDetail: {},
            // 表单数据
            formModel: {
                id: '',
                businessDistrictName: '',
                businessDistrictSn: '',
                businessDistrictLogo: [],
                openTime: '',
                businessDistrictDesc: '',
                regionId: '',
                detailAddress: '',
                contactFullname: '',
                contactTel: '',
                isAvailable: true,
                isDefault: false,
                latLng: '',
                businessDistrictStatus: 100,
            },
            // 表单校验规则
            formRules: {
                businessDistrictName: {
                    required: true,
                    message: '请输入商业区名称',
                    trigger: 'blur',
                },
                /* businessDistrictLogo: {
                    type: 'array',
                    required: true,
                    message: '请上传商业区Logo',
                }, */
                openTime: {
                    required: true,
                    message: '请输入营业时间',
                    trigger: 'blur',
                },
                regionId: {
                    required: true,
                    message: '请选择商业区地址',
                    trigger: 'blur',
                },
                detailAddress: {
                    required: true,
                    message: '请输入商业区详细地址',
                    trigger: 'blur',
                },
                contactFullname: {
                    required: true,
                    message: '请输入联系人姓名',
                    trigger: 'blur',
                },
                contactTel: {
                    required: true,
                    message: '请输入联系人电话',
                    trigger: 'blur',
                },
                isAvailable: {
                    required: true,
                    message: '请选择是否可用',
                },
                isDefault: {
                    required: true,
                    message: '请选择是否默认',
                },
                latLng: {
                    required: true,
                    message: '请选择商业区坐标',
                    trigger: 'blur',
                },
                businessDistrictStatus: {
                    required: true,
                    message: '请选择商业区状态',
                },
            },
            selectApi: this.$api.Rs.Region,
        };
    },
    computed: {
        businessDistrictId() {
            return this.$route.params.id || '';
        },
        businessDistrict() {
            return this.businessDistrictDetail.businessDistrict || {};
        },
    },
    methods: {
        onConfirm() {
            const { addForm } = this.$refs;
            addForm.validate().then(() => {
                this.$api.Sp.BusinessDistrict.save({
                    ...this.formModel,
                }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.$router.back();
                });
            });
        },
        getDetail() {
            this.formModel.id = this.businessDistrictId;
            if (this.formModel.id) {
                this.$api.Sp.BusinessDistrict.getDetail({ id: this.formModel.id }).then(json => {
                    const res = json.data.data;
                    this.businessDistrictDetail = res;
                    this.$utils.formModelMerge(this.formModel, res.businessDistrict);
                });
            }
        },
    },
    created() {
        this.getDetail();
    },
};
</script>

<style lang="scss">
</style>
