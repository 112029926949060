<!-- Created by henian.xu on 2018/7/20. -->
<template>
    <page back>
        <!-- 表单区 -->
        <template slot="secondHeader">
            <el-form
                ref="queryForm"
                class="second-header npa-b"
                size="small"
                :inline="true"
                :model="queryFormModel"
            >
                <el-form-item
                    prop="storeName"
                    label="门店名称"
                >
                    <el-input
                        v-model="queryFormModel.storeName"
                        placeholder="请输入门店名称"
                    />
                </el-form-item>
                <el-form-item
                    prop="storeSn"
                    label="门店编号"
                >
                    <el-input
                        v-model="queryFormModel.storeSn"
                        placeholder="请输入门店编号"
                    />
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        @click="onQuery()"
                    >
                        查询
                    </el-button>
                    <el-button
                        type="success"
                        @click="onReset"
                    >
                        重置
                    </el-button>
                </el-form-item>
            </el-form>
        </template>

        <!-- 内容 -->
        <div
            class="body"
            slot="body"
            v-loading="querying"
        >
            <!-- 操作 -->
            <div
                class="ma-b"
            >
                <el-button-group>
                    <el-button
                        type="success"
                        icon="el-icon-plus"
                        size="small"
                        @click="onAdd"
                    >
                        关联门店
                    </el-button>
                </el-button-group>
            </div>

            <!-- 表格 -->
            <el-table
                stripe
                border
                size="small"
                :data="tableData"
                style="width: 100%"
                ref="selectStoreDialogTable"
                @selection-change="onTableSelectionChange"
            >
                <el-table-column
                    type="selection"
                    width="36"
                />
                <el-table-column
                    prop="storeName"
                    label="门店名称"
                    min-width="100"
                />
                <el-table-column
                    prop="storeSn"
                    label="门店编号"
                    min-width="100"
                />
                <el-table-column
                    fixed="right"
                    label="操作"
                    width="80"
                >
                    <template slot-scope="scope">
                        <el-button
                            type="text"
                            size="small"
                            @click="cancelRelationStore(scope.row.id)"
                        >
                            取消关联
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>

            <!-- 分页 -->
            <div class="ma-t ta-r">
                <pagination
                    v-model="pagination"
                    @input="onQuery"
                />
            </div>
        </div>

        <!-- 弹框 -->
        <!-- 新增/编辑 -->
        <el-dialog
            title="关联门店"
            center
            width="1200px"
            :visible.sync="addDialog.isVisible"
            @closed="onAddDialogCancel"
        >
            <!-- 表单区 -->
            <el-form
                ref="addDialogQueryForm"
                class="second-header npa-b"
                size="small"
                :inline="true"
                :model="addDialog.formModel"
            >
                <el-form-item
                    prop="storeName"
                    label="门店名称"
                >
                    <el-input
                        v-model="addDialog.formModel.storeName"
                        placeholder="请输入门店名称"
                    />
                </el-form-item>
                <el-form-item
                    prop="storeSn"
                    label="门店编号"
                >
                    <el-input
                        v-model="addDialog.formModel.storeSn"
                        placeholder="请输入门店编号"
                    />
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        @click="onSelectStoreDialogQuery()"
                    >
                        查询
                    </el-button>
                    <el-button
                        type="success"
                        @click="onResetStoreAddDialog"
                    >
                        重置
                    </el-button>
                </el-form-item>
            </el-form>

            <!-- 内容 -->
            <div class="body">
                <!-- 表格 -->
                <el-table
                    stripe
                    border
                    size="small"
                    :data="addDialog.tableData"
                    style="width: 100%"
                    height="400"
                    row-key="id"
                    ref="addDialogStoreTable"
                    @selection-change="onAddDialogSelectionChange"
                >
                    <el-table-column
                        type="selection"
                        width="36"
                    />
                    <el-table-column
                        prop="storeName"
                        label="门店名称"
                        min-width="100"
                    />
                    <el-table-column
                        prop="storeSn"
                        label="门店编号"
                        min-width="100"
                    />
                </el-table>
                <!-- 分页 -->
                <div class="ma-t ta-r">
                    <pagination
                        v-model="addDialog.pagination"
                        @input="onSelectStoreDialogQuery"
                    />
                </div>
            </div>
            <div
                slot="footer"
                class="dialog-footer"
            >
                <el-button
                    type="primary"
                    size="small"
                    @click="onAddDialogConfirm"
                >
                    关 联
                </el-button>
                <el-button
                    size="small"
                    @click="onAddDialogCancel"
                >
                    取 消
                </el-button>
            </div>
        </el-dialog>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';
import { queryFormList } from 'vmf/src/mixins';

export default {
    name: 'ShopStorestore',
    mixins: [pagesMixin, queryFormList],
    data() {
        return {
            queryFormModel: {
                businessDistrictId: '',
                storeName: '', // 门店名称
                storeSn: '', // 门店编码
            },
            // 表格数据
            tableData: [],
            // 表格当前选中行
            currentTableSelect: [],
            // 新增/编辑
            addDialog: {
                // 对话框显示
                isVisible: false,
                // 表单数据
                formModel: {
                    storeName: '',
                    storeSn: '',
                    isAvailable: 1,
                },
                // 表单数据
                tableData: [],
                // 分页
                pagination: {},
                // 已选择数据
                currentTableSelect: [],
            },
        };
    },
    methods: {
        /* -- 方法 -- */
        // 请求列表数据
        getListData(queryData) {
            return this.$api.Sp.ShopStore.selectByPage(queryData).then(json => {
                const res = json.data;
                this.tableData = res.data;
                this.pagination = res.pagination;
                return Promise.resolve(json);
            });
        },
        /* -- 事件 -- */
        onAdd() {
            this.addDialog.isVisible = true;
            this.$nextTick(() => {
                this.onSelectStoreDialogQuery();
            });
        },
        // 查询
        onSelectStoreDialogQuery(pagination) {
            return this.$api.Sp.ShopStore.selectByPage({
                ...this.addDialog.formModel,
                relBusinessDistrictId: 'isnull',
                ...pagination,
            }).then(json => {
                const res = json.data;
                this.addDialog.tableData = res.data;
                this.addDialog.pagination = res.pagination;
            });
        },
        // 重置
        onResetStoreAddDialog() {
            this.$refs.addDialogQueryForm.resetFields();
        },
        onTableSelectionChange(val) {
            this.currentTableSelect = val;
        },
        onAddDialogSelectionChange(val) {
            this.addDialog.currentTableSelect = val;
        },
        // 保存关联门店
        onAddDialogConfirm() {
            const list = [];
            this.addDialog.currentTableSelect.forEach(item => {
                list.push(item.id);
            });
            this.$api.Sp.BusinessDistrict.relationStore({
                businessDistrictId: this.queryFormModel.businessDistrictId,
                storeIds: list.join(','),
            }).then(json => {
                const res = json.data;
                this.$message({
                    message: res.msg,
                    type: 'success',
                });
                this.onQuery(this.pagination);
                this.addDialog.isVisible = false;
            });
        },
        // 取消关联
        cancelRelationStore(id) {
            this.$confirm('是否确定要取消关联！', '温馨提示', {
                type: 'warning',
            }).then(() => {
                this.$api.Sp.BusinessDistrict.cancelRelationStore({
                    businessDistrictId: this.queryFormModel.businessDistrictId,
                    storeIds: id,
                }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.onQuery();
                });
            });
        },
        onAddDialogCancel() {
            this.addDialog.isVisible = false;
        },
    },
    created() {
        this.queryFormModel.businessDistrictId = this.$route.params.id;
    },
};
</script>

<style lang="scss">
</style>
